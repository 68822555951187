<div class="flex h-full flex-col justify-around overflow-hidden p-4">
  <div class="flex justify-between">
    <h4>
      {{ data.header | translate }}
    </h4>
    <button
      ui-button
      size="icon-small"
      variant="tertiary"
      (click)="close()"
      y
      *ngIf="data.header !== 'session-timeout-header'"
    >
      <app-close-icon />
    </button>
  </div>
  <div class="text-break overflow-hidden">
    <p>
      {{ data.content | translate }}
    </p>
  </div>
  <div class="flex justify-end gap-3">
    <button
      ui-button
      *ngFor="let action of data.actions"
      variant="{{ action.type }}"
      [mat-dialog-close]="data"
      (click)="onActionClick(action.type)"
    >
      {{ action.action | translate }}
    </button>
  </div>
</div>

import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { AuthService } from "@auth0/auth0-angular";
import DialogModel from "../../../app/models/common/DialogModel";
import { ConfirmationDialogComponent } from "../../shared/confirmation-dialog/confirmation-dialog.component";
import { ToasterService } from "../toaster.service";
import { LogoutService } from "./logout.service";
import { OverlayContainer } from "@angular/cdk/overlay";
@Injectable({
  providedIn: "root",
})
export class SessionExpiredService {
  constructor(
    private dialog: MatDialog,
    public router: Router,
    private logoutService: LogoutService,
    private overlayContainer: OverlayContainer
  ) {}
  dialogModel = new DialogModel();

  openDialog() {
    this.dialogModel.content = "session-timeout-content";
    this.dialogModel.header = "session-timeout-header";
    this.dialogModel.actions = [
      {
        action: "OK",
        type: "primary",
      },
    ];
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: "37.5rem",
      height: "13rem",
      data: this.dialogModel,
      autoFocus: false,
    });
    dialogRef.afterClosed().subscribe(async (result) => {
      this.dialog.closeAll();
      const elements = this.overlayContainer.getContainerElement();
      elements.innerHTML='';

      this.logoutService.logout();
    });
  }
}
